/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useEffect, useState } from "react"
import { motion, useMotionValue, useSpring } from "framer-motion"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"
import styled from "styled-components"
import cursor from "../../assets/images/cursor.jpg"
const CursorStyles = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;

  opacity: 1;

  z-index: ;
  pointer-events: none;

  .point {
    border: 1px solid #ff8bcd;
    height: 1px;
    width: 1px;
  }
`
const CursorStyles2 = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: unset;
  @media (min-width: 768px) {
    background: url(${cursor});
    backdrop-filter: blur(5px);
    background-size: cover;
    background-position: center center;
  }

  z-index: 1;
  pointer-events: none;

  overflow: hidden;
`

export default function Cursor() {
  // Cursor
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)
  const springConfig = { damping: 70, stiffness: 1000 }
  const cursorXSpring = useSpring(cursorX, springConfig)
  const cursorYSpring = useSpring(cursorY, springConfig)
  const springConfig2 = { stiffness: 500, damping: 30 }
  const cursorXSpring2 = useSpring(cursorX, springConfig2)
  const cursorYSpring2 = useSpring(cursorY, springConfig2)

  useEffect(() => {
    // cursor
    const moveCursor = e => {
      cursorX.set(e.clientX - 5)
      cursorY.set(e.clientY - 5)
    }
    window.addEventListener("mousemove", moveCursor)
    return () => {
      window.removeEventListener("mousemove", moveCursor)
    }
  }, [])
  return (
    <>
      <CursorStyles2
        style={{
          translateX: cursorXSpring2,
          translateY: cursorYSpring2,
        }}
      ></CursorStyles2>
    </>
  )
}
