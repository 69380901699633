/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useEffect, useState } from "react"

import { useFrame } from "@react-three/fiber"
import * as THREE from "three"

//mouse
import { useMouse } from "rooks"

//window
import {
  useBoundingclientrect,
  useBoundingclientrectRef,
  useWindowSize,
} from "rooks"

export default function Light({ v = new THREE.Vector3() }) {
  const mouse = useMouse()
  const light = useRef()
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()
  const centerX = innerWidth / 2
  const centerY = innerHeight / 2
  useFrame(state => {
    // console.log((mouse.clientX / innerWidth) * 2 - 1)
    //const centerX = innerWidth / 2
    //console.log(centerX)
    //console.log(state.mouse.x * 500)
    // console.log(mouse.clientY)
    // console.log(((mouse.clientX / innerWidth) * 2 - 1) * -500)
    //console.log(((mouse.clientY / innerHeight) * 2 - 1) * -500)
    light.current.position.set(
      ((mouse.clientX / innerWidth) * 2 - 1) * 500,
      ((mouse.clientY / innerHeight) * 2 - 1) * -500,
      50
    )

    /*

   
       light.current.position.set(
      state.mouse.x * 500,
      state.mouse.y * 500,

      50
    )
    
     light.current.position.set(
      (mouse.clientX / innerWidth) * 2 - 1 * 500,
      (mouse.clientY / innerHeight) * 2 - 1 * 500,
      mouse.clientY * 0.1,
      50
    )
    */
  })
  return <pointLight ref={light} decay={2} intensity={10} color={"0xffbdd7"} />
}
