/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Cursor from "./cursor/cursor"
import "./layout.css"
import Header from "./header/header"
import Frame from "./header/frame"
import Hero from "./index/hero"
import background2 from "../assets/images/bgfinal.jpg"
import MobileMenu from "./MobileMenu/MobileMenu"
import MobileMenuButton from "./header/MobileMenuButton"
const BG = styled.div`
  position: fixed;
  background: url(${background2});
  background-size: cover;
  background-position: center center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
`

const Layout = ({ path, children }) => {
  const [menuOpen, setmenuOpen] = useState(false)

  const handleMenuClick = event => {
    setmenuOpen("closed")
  }

  //query title
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [aside, setAside] = useState(false)

  function handleChange(aside) {
    setAside(!aside)
  }

  return (
    <>
      <MobileMenuButton menuOpen={menuOpen} setmenuOpen={setmenuOpen} />
      <MobileMenu
        onChange={handleChange}
        menuOpen={menuOpen}
        setmenuOpen={setmenuOpen}
      />
      <Frame />
      <Header onChange={handleChange} />
      <Cursor />
      {children}
      <Hero aside={path === "/" ? false : true} />
      <BG />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
