import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Socials from "./socials"
import { StaggerTextReveal } from "stagger-text-reveal-animation"

const TopLeftStyles = styled.div`
  position: fixed;
  left: 80px;
  color: var(--white);
  top: 50px;
  z-index: 200;
  font-family: "PPNeue";
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
  a {
    color: var(--white);
  }
`
const BottomLeftStyles = styled.div`
  position: fixed;
  bottom: 50px;
  left: 80px;
  color: var(--white);
  z-index: 200;
  font-family: "PPNeue";
  text-transform: uppercase;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`
const BottomRightStyles = styled.div`
  position: fixed;
  bottom: 50px;
  right: 80px;
  color: var(--white);
  z-index: 200;
  font-family: "PPNeue";
  text-transform: uppercase;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`
const TopRightStyles = styled.div`
  position: fixed;
  top: 50px;
  right: 80px;
  color: var(--white);
  z-index: 200;
  font-family: "PPNeue";
  text-transform: uppercase;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`
export default function Frame() {
  return (
    <>
      <TopLeftStyles>
        <Link to="/">
          {" "}
          <StaggerTextReveal
            fontSize={16}
            height={18}
            text={"BLACKPOWERBARBIE'S"}
            stagger={50}
            triggerAfter={0.25}
            duration={1.25}
          />
          <StaggerTextReveal
            fontSize={16}
            height={18}
            text={"WORLD"}
            stagger={100}
            triggerAfter={0.35}
            duration={1.25}
          />
        </Link>
      </TopLeftStyles>
      <TopRightStyles>
        {" "}
        <Socials />
      </TopRightStyles>
      <BottomLeftStyles>
        <StaggerTextReveal
          fontSize={16}
          height={18}
          text={"Visual Storyteller"}
          stagger={50}
          triggerAfter={0.45}
          duration={1.25}
        />
      </BottomLeftStyles>
      <BottomRightStyles>
        <StaggerTextReveal
          fontSize={16}
          height={18}
          text={"TORONTO | NYC"}
          stagger={50}
          triggerAfter={0.5}
          duration={1.25}
        />
      </BottomRightStyles>
    </>
  )
}
