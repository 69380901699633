import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons"
import { motion } from "framer-motion"
const SocialsStyled = styled.div`
  font-family: "IBM Regular", courier;
  font-size: 18px;

  ul {
    margin: 0;
    display: inline-flex;
    padding: 0;
    align-items: space-between;
    justify-content: flex-end;
    width: 100%;
    li {
      display: flex;
      align-items: center;

      border-radius: 30px;
      display: flex;

      justify-content: flex-end;
      margin: 0 8px;
      padding: 0;

      a {
        font-size: 1.2em;
        color: var(--white);
        display: inherit;
        &:hover {
          color: var(--yellow);
        }
      }
    }
  }
`

export default function Socials() {
  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  }
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  }

  return (
    <>
      <SocialsStyled>
        <div className="socials">
          <motion.ul variants={container} initial="hidden" animate="show">
            <motion.li variants={item}>
              <a
                href="https://www.instagram.com/blackpowerbarbie/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </motion.li>
            <motion.li variants={item}>
              <a href="https://twitter.com/blackpwrbarbie" target="_blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </motion.li>
          </motion.ul>
        </div>
      </SocialsStyled>
    </>
  )
}
