import React from "react"
import Layout from "./src/components/layout"
import { AnimatePresence } from "framer-motion"
import GlobalStyles from "./src/styles/GlobalStyles"
import Typography from "./src/styles/Typography"
import "./src/styles/preloader.scss"

export function wrapPageElement({ element, props }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Layout {...props}>
        <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
      </Layout>
    </>
  )
}
