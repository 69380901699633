import React, { useState, useEffect, useRef } from "react"

import { Suspense } from "react"
import styled from "styled-components"
import { useMediaQuery, MediaQuery } from "react-responsive"
import { Link } from "gatsby"
//3d
import * as THREE from "three"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import {
  Environment,
  Float,
  Sparkles,
  Billboard,
  SpotLight,
  Bounds,
  Loader,
  Lightformer,
} from "@react-three/drei"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { EffectComposer, Bloom } from "@react-three/postprocessing"
import { KernelSize } from "postprocessing"

//models
import Model from "../3dmodels/model"
import Light from "../3dmodels/light"
import Star1 from "../3dmodels/star1"
//mouse
import {
  useBoundingclientrect,
  useBoundingclientrectRef,
  useWindowSize,
  useMouse,
} from "rooks"

const PortalStyles = styled.div`
  position: absolute;
  height: 667px;
  left: 0;
  right: 0;
  top: 0;

  color: var(--white);
  overflow: hidden;
  margin-top: 0px;
  @media (min-width: 1024px) {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  z-index: 0;
  //pointer-events: none;
`
const PortalWrapStyles = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 667px;

  @media (min-width: 1024px) {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
`

export default function Hero({ aside }) {
  const mouse = useMouse()
  // console.log(info)
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize()
  const ref = React.useRef(null)

  //queries

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  })

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" })

  const camPosition = isTabletOrMobile ? [0, 0, 300] : [0, 0, 300]

  //define reference for point light
  const light = React.useRef()

  function Rig({ v = new THREE.Vector3() }) {
    return useFrame(state => {
      if (isDesktopOrLaptop) {
        state.camera.position.lerp(
          v.set(
            ((mouse.clientX / innerWidth) * 2 - 1) * 5,
            ((mouse.clientY / innerHeight) * 2 - 1) * -5,
            300
          ),
          0.1
        )
      }
    })
  }

  //sparkles
  //sparkles
  const scale = Array.from({ length: 50 }, () => 500 + Math.random() * 4)

  function MovingSpots({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
    const group = useRef()
    useFrame(
      (state, delta) =>
        (group.current.position.z += delta * 15) > 60 &&
        (group.current.position.z = -60)
    )
    return (
      <group rotation={[0, 0.5, 0]}>
        <group ref={group}>
          {positions.map((x, i) => (
            <Lightformer
              form="circle"
              intensity={4}
              rotation={[Math.PI / 2, 0, 0]}
              position={[x, 4, i * 4]}
              scale={[3, 1, 1]}
            />
          ))}
        </group>
      </group>
    )
  }
  return (
    <>
      <PortalStyles ref={ref}>
        <PortalWrapStyles className="canvasWrap">
          <Canvas
            orthographic
            camera={{
              zoom: 2.5,

              //position: camPosition,
              fov: 90,
              near: -600,
              far: 600,
            }}
          >
            <Suspense fallback={null}>
              <Bounds margin={1}>
                <Model aside={aside} />
                <Environment files="/sunset2-star.hdr"></Environment>
              </Bounds>
            </Suspense>
            {/*}
              <Billboard
              follow={false}
              lockX={true}
              lockY={true}
              lockZ={true} // Lock the rotation on the z axis (default=false)
            >
              <Star1 />
              <ambientLight intensity={2} />
         
            </Billboard>
            <EffectComposer multisampling={8}>
              <Bloom
                kernelSize={1}
                luminanceThreshold={0}
                luminanceSmoothing={0.5}
                intensity={0.3}
              />
            </EffectComposer>
          {*/}
            <ambientLight intensity={0} />
            <Rig />
            <Light />
          </Canvas>
        </PortalWrapStyles>
      </PortalStyles>
    </>
  )
}

/*
  <pointLight
  ref={light}
  castShadow
  intensity={100}
  color={"white"}
  position={[0, 0, 0]}
/>*/

/*
 <Sparkles
                count={scale.length}
                size={scale}
                position={[0, 0.9, 0]}
                scale={[800, 800, 200]}
                speed={3}
                color={"pink"}
              />
              */
