/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF, useTexture } from "@react-three/drei"
import MediaQuery from "react-responsive"
import { motion } from "framer-motion-3d"
export default function Model() {
  const group = useRef()
  const { nodes, materials } = useGLTF("/star1.gltf")
  const props = useTexture({
    aoMap: "/textures/default_occlusionRoughnessMetallic.png",
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        scale={[-20, 50, 0]}
        position={[-120, -140, 300]}
        rotation={[0, 0, 100]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
      >
        <meshStandardMaterial {...props} color="#fd9808" />
      </mesh>
      <mesh
        scale={[20, 40, 0]}
        position={[100, 100, 30]}
        rotation={[0, 0, -100]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
      ></mesh>
      <mesh
        scale={[30, 30, 0]}
        position={[200, -100, 30]}
        rotation={[0, 0, 100]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#fd9808" />
      </mesh>
      <mesh
        scale={[10, 20, 0]}
        position={[-210, 0, 120]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#F350AD" />
      </mesh>
      <mesh
        scale={[10, 20, 0]}
        position={[300, 50, 200]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#F350AD" />
      </mesh>
      <mesh
        scale={[15, 40, 0]}
        position={[-300, -50, 200]}
        rotation={[0, 0, -50]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#ffffff" />
      </mesh>
      <mesh
        scale={[35, 50, 0]}
        position={[300, -50, 200]}
        rotation={[0, 0, -50]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#ffffff" />
      </mesh>
      <mesh
        scale={[30, 50, 0]}
        position={[-400, -150, 0]}
        rotation={[0, 0, 50]}
        castShadow
        receiveShadow
        geometry={nodes.Node.geometry}
        material={materials["default"]}
      >
        <meshStandardMaterial {...props} color="#ffffff" />
      </mesh>
    </group>
  )
}

useGLTF.preload("/star1.gltf")
