import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { videoTagString, VideoTag } from "react-video-tag"
import video from "../../assets/video/bg-crop.mp4"
import poster from "../../assets/video/poster.jpg"
import { motion } from "framer-motion"

const MobileMenuStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black);
  z-index: 10001;
  transform: translateY(-100%);
  transform: translateY(0px);
  transition: transform 0.75s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  &.open {
    transform: translateY(0);
  }
  &.closed {
    transform: translateY(-100%);
  }

  #background-video {
    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: none;
    position: fixed; /* optional depending on what you want to do in your app */
    object-fit: cover;
    object-position: right center;
    z-index: 1;
  }
  .header {
    position: absolute;
    top: 32px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    z-index: 1;
    h1 {
      line-height: 0.8;
    }
  }

  menu {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-direction: column;
      @media (min-width: 800px) {
        flex-direction: row;
      }
      li {
        font-size: 1.8em;
        list-style: none;
        text-transform: uppercase;
        line-height: 2;
        padding: 0px 20px;
        a {
          font-family: "PPNeue", "courier", sans-serif;
          padding: 5px;
          color: var(--white);
          text-decoration: none;
          &.active {
            color: var(--pink);
          }
          &.hover {
            color: var(--pink);
          }
        }
      }
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
const MenuCanvas = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
`

export default function MobileMenu({ menuOpen, setmenuOpen, onChange }) {
  function moveModel() {
    onChange(false)
  }
  function resetModel() {
    onChange(true)
  }

  const className = menuOpen ? "open" : "closed"

  const handleMenuClick = event => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');
    setmenuOpen(!menuOpen)
    moveModel()
  }
  const handleMenuClickHome = event => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');
    setmenuOpen(!menuOpen)
    resetModel()
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.9,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  }
  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  }
  return (
    <>
      <MobileMenuStyles className={className}>
        <menu>
          <motion.ul
            variants={container}
            initial="hidden"
            animate={menuOpen ? "show" : "hidden"}
          >
            <motion.li variants={item} className="b">
              <Link
                to="/"
                activeClassName="active"
                onClick={event => handleMenuClickHome(event)}
              >
                Home
              </Link>
            </motion.li>
            <motion.li variants={item}>
              {" "}
              <Link
                to="/personal"
                activeClassName="active"
                onClick={event => handleMenuClick(event)}
              >
                Personal
              </Link>
            </motion.li>
            <motion.li variants={item}>
              {" "}
              <Link
                to="/clients"
                activeClassName="active"
                onClick={event => handleMenuClick(event)}
              >
                Clients
              </Link>
            </motion.li>
            <motion.li variants={item}>
              {" "}
              <Link
                to="/shop"
                activeClassName="active"
                onClick={event => handleMenuClick(event)}
              >
                Shop
              </Link>
            </motion.li>
            <motion.li variants={item}>
              {" "}
              <Link
                to="/about"
                activeClassName="active"
                onClick={event => handleMenuClick(event)}
              >
                About
              </Link>
            </motion.li>
          </motion.ul>
        </menu>
        <video
          id="background-video"
          loop="true"
          autoplay="autoplay"
          muted
          poster={poster}
        >
          <source src={video} type="video/mp4" />
        </video>
      </MobileMenuStyles>
    </>
  )
}
