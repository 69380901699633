import React, { useState, useEffect } from "react"

import styled from "styled-components"

import { Link } from "gatsby"
import burger from "../../assets/images/menu.svg"
import close from "../../assets/images/close.svg"
const MobileMenuButtonStyles = styled.div`
  position: fixed;

  top: 40px;
  right: 20px;
  display: flex;
  background: none;
  @media (min-width: 800px) {
    top: 40px;
    right: 40px;
    background: var(--green);
  }
  @media (min-width: 1200px) {
    top: 40px;
    right: 40px;
    display: none;
  }
  width: 65px;
  height: 65px;

  z-index: 100000;
  border-radius: 100%;

  align-items: center;
  justify-content: center;
  img {
    margin: 0;
    padding: 0;
  }
`

export default function MobileMenuButton({ menuOpen, setmenuOpen }) {
  //scroll pos , and set scroll of header fixed
  const className = menuOpen ? "open" : "closed"
  const [blink, setBlink] = useState("eye")

  const handleMenuClick = event => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');
    setmenuOpen(!menuOpen)
  }

  return (
    <MobileMenuButtonStyles onClick={event => handleMenuClick(event)}>
      <img width="30px" src={menuOpen ? close : burger}></img>
    </MobileMenuButtonStyles>
  )
}
