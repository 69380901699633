import { createGlobalStyle } from "styled-components"

import headerfont from "../assets/fonts/MagneticFields-Thin.woff"
import bodyfontregular from "../assets/fonts/IBM-Plex-Mono-Regular.woff"
import bodyfontitalic from "../assets/fonts/IBM-Plex-Mono-Italic.woff"
import bodyfontlight from "../assets/fonts/IBM-Plex-Mono-300.woff"
import bodyfontlightitalic from "../assets/fonts/IBM-Plex-Mono-300italic.woff"
import bodyfont600 from "../assets/fonts/IBM-Plex-Mono-600.woff"
import bodyfont600italic from "../assets/fonts/IBM-Plex-Mono-600italic.woff"
import bodyfont700 from "../assets/fonts/IBM-Plex-Mono-700.woff"
import bodyfont700italic from "../assets/fonts/IBM-Plex-Mono-700italic.woff"
import headingfont from "../assets/fonts/PPNeueMachina-PlainUltrabold.woff"
import headingfontregular from "../assets/fonts/ppneuemachina-inktrapregular.woff"
const Typography = createGlobalStyle`
  @font-face {
    font-family : 'PPneueregular';
    src: url(${headingfontregular})
  }
  @font-face {
    font-family: 'PPNeue';
    src: url(${headingfont});
  }


  html {
    font-family: 'PPNeue', 'courier', sans-serif;
    color: var(--mfblack);
  }
  p, li {
  
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'PPNeue', Impact, 'Helvetica Neue', sans-serif;
    font-weight: normal;
    margin: 0;
    line-height:1
  }
  a {
    color: var(--mfblack);
    
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

  p{
	letter-spacing:0;
}
strong{
  font-family: 'IBM Semi-Bold 600',  sans-serif;
}


  .l {
    font-family: 'IBM Light 300',  sans-serif;
  }
  .l-i {
    font-family: 'IBM Light 300 Italic',  sans-serif;
  }
  .sb {
    font-family: 'IBM Semi-Bold 600',  sans-serif;
  }
  .sb-i {
    font-family: 'IBM Semi-Bold 600 Italic',  sans-serif;
  }
  .b {
    font-family: 'IBM Bold 700',  sans-serif;
  }
 
`

export default Typography

/*
  @font-face {
    font-family: 'IBM Semi-Bold 600 Italic';
    src: url(${bodyfont700italic});
  }
    @font-face {
    font-family: 'IBM Light 300 Italic';
    src: url(${bodyfontlightitalic});
  }
  
    @font-face {
    font-family: 'IBM Regular Italic';
    src: url(${bodyfontitalic});
  }
    @font-face {
    font-family: 'IBM Bold 700 Italic';
    src: url(${bodyfont700italic});
  }

 .b-i {
    font-family: 'IBM Bold 700 Italic',  sans-serif;
  }

    .i {
    font-family: 'IBM Regular Italic',  sans-serif;
  }

  */
