/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useEffect, useState } from "react"
import { useGLTF, useTexture } from "@react-three/drei"

import MediaQuery from "react-responsive"
import { motion } from "framer-motion-3d"

//3d
import { Sparkles } from "@react-three/drei"

export default function Model({ aside }) {
  const group = useRef()
  const { nodes, materials } = useGLTF("/bpb_original.gltf")
  const myMesh = React.useRef()

  //textures

  const props = useTexture({
    colorMap: "/textures/default_baseColor.png",
    displacementMap: "/textures/Metal034_1K_Displacement.jpg",
    normalMap: "/textures/Metal034_1K_NormalGL.jpg",
    roughnessMap: "/textures/Metal034_1K_Roughness.jpg",
    metalnessMap: "/textures/Metal034_1K_Metalness.jpg",
    aoMap: "/textures/default_occlusionRoughnessMetallic.png",
  })

  const props2 = useTexture({
    map: "/textures/gold-scuffed_basecolor-boosted.png",
    //displacementMap: "/textures/Metal034_1K_Displacement.jpg",
    normalMap: "/textures/gold-scuffed_normal.png",
    roughnessMap: "/textures/gold-scuffed_roughness.png",
    metalnessMap: "/textures/gold-scuffed_metallic.png",
    aoMap: "/textures/default_occlusionRoughnessMetallic.png",
  })

  //states
  //const [aside, setAside] = useState(false)

  //rotate function
  //sparkles
  const scale = Array.from({ length: 50 }, () => 300 + Math.random() * 4)
  return (
    <>
      <motion.group
        ref={group}
        {...props}
        dispose={null}
        animate={aside ? "moved" : "notmoved"}
      >
        <MediaQuery minWidth={0} maxWidth={375}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                y: 100,
                scale: 0.75,
                scale: 0.25,
              },
            }}
            //(!aside)}
            castShadow
            receiveShadow
            ref={myMesh}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}
            scale={[0.35, 0.35, 0.35]}
            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>
        <MediaQuery minWidth={376} maxWidth={640}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                y: 100,
                scale: 0.75,
                scale: 0.25,
              },
            }}
            //(!aside)}
            scale={[0.3, 0.3, 0.3]}
            ref={myMesh}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}

            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>
        <MediaQuery minWidth={641} maxWidth={1025}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                y: 100,
                scale: 0.75,
                scale: 0.25,
              },
            }}
            //(!aside)}

            ref={myMesh}
            scale={[0.45, 0.45, 0.45]}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}

            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>

        <MediaQuery minWidth={1025} maxWidth={1199}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                y: 100,
                scale: 0.75,
                scale: 0.25,
              },
            }}
            //(!aside)}

            ref={myMesh}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}

            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>
        <MediaQuery minWidth={1200} maxWidth={1399}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                y: 100,
                scale: 0.75,
                scale: 0.35,
              },
              side: {
                // rotateY: Math.PI / 4,
                //  rotateZ: Math.PI / 12,
                x: 0,
                scale: 0.25,
                scale: 0.35,
              },
            }}
            //(!aside)}

            ref={myMesh}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}

            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>

        <MediaQuery minWidth={1400} minHeight={300}>
          <motion.mesh
            transition={{
              rotateY: { type: "spring", bounce: 0.5 },
              scale: { type: "spring", bounce: 0.6 },
            }}
            variants={{
              moved: {
                x: -240,
                scale: 0.75,
                scale: 0.35,
              },
            }}
            //(!aside)}

            ref={myMesh}
            // position={active ? [-200, 0, 0] : [0, 0, 0]}
            //rotation={active ? [0, Math.PI / 3, 0] : [0, 0, 0]}

            //castShadow
            // receiveShadow
            geometry={nodes.bpb.geometry}
          >
            <meshStandardMaterial
              {...props2}
              // color="#db68bb"
              //#db68bb

              //  color="#FFBA33"
              //ffcc88
              metalness="1"
              roughness="0"
              // clearcoat="1"
            />
          </motion.mesh>
        </MediaQuery>
      </motion.group>
    </>
  )
}

useGLTF.preload("/bpb_original.gltf")
