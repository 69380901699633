import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

const HeaderStyles = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
  z-index: 101;
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
  menu {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: space-between;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline;
      margin: 0 10px;
      color: var(--white);
      font-family: "PPNeue";

      a {
        color: var(--white);
        text-decoration: none;
        &.active {
          color: var(--pink);
        }
      }
    }
  }
`

export default function Header({ onChange }) {
  function moveModel() {
    onChange(false)
  }
  function resetModel() {
    onChange(true)
  }
  return (
    <HeaderStyles>
      <menu>
        <ul>
          <li>
            <Link activeClassName="active" to="/personal" onClick={moveModel}>
              PERSONAL
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/clients" onClick={moveModel}>
              CLIENTS
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/shop">
              SHOP
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/about">
              STORY
            </Link>
          </li>
        </ul>
      </menu>
    </HeaderStyles>
  )
}
